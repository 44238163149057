<template>
	<el-scrollbar class="uu-box">
		<div class="t-page">
			<div class="t-page-head">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.home')}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{$t('seller.breadcrumb.teaching')}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-wrap">
				<div class="page-title">
					<h2>{{$t('seller.article.title')}}</h2>
					<p>{{$t('seller.article.subtitle')}}</p>
				</div>
				<div class="page-main">
					<div class="item" v-for="item in articleList" :key="item.cate_id">
						<div class="item-label">
							<div class="name">{{ item.cate_name }}</div>
							<div class="icon"><img :src="item.icon_url" alt=""></div>
						</div>
						<div class="item-block">
							<div class="item-path" v-for="child in item.childrens" :key="child.cate_id">
								<div class="path-name">{{ child.cate_name }}</div>
								<ul class="path-box">
									<li v-for="article in child.list_article" :key="article.article_id">
										<router-link :to="{ name: 'teachingInfo', params: { id: article.article_id }}">{{ article.title }}</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
import { fetchArticleList } from "@/api/seller/assess"
export default {
	data() {
		return {
			articleList: []
		}
	},
	created () {
		this.getArticleList()
	},
	methods: {
		getArticleList() {
			fetchArticleList().then(response => {
				this.articleList = response.data
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.t-page {
	padding: 20px;

	.t-page-head {
		padding-bottom: 20px;

		.title {
			line-height: 20px;
			font-size: 16px;
			font-weight: bold;
		}
		::v-deep .el-breadcrumb {
			.el-breadcrumb__inner {
				&.is-link {
					color: $--seller-primary;
				}
			}
		}
	}

	.t-page-wrap {
		padding: 30px 0 10px;
		.page-title {
			text-align: center;
			h2 {
				font-size: 32px;
				font-weight: 600;
				line-height: 1.5;
			}
			p {
				font-size: 14px;
				color: #73777a;
				line-height: 24px;
				margin-bottom: 25px;
			}
		}
		.page-main {
			padding: 0 70px 40px 45px;
			.item {
				display: flex;
				align-items: baseline;
				position: relative;
				.item-label {
					width: 160px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					.name {
						font-size: 18px;
						margin-right: 15px;
						font-weight: 500;
					}
					.icon {
						width: 60px;
						height: 60px;
						padding: 5px 0;
						background-color: #ffffff;
						z-index: 2;
						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}
				}
				.item-block {
					flex: 1;
					width: 0;
					margin-left: 20px;
					.item-path {
						cursor: pointer;
						padding: 15px 0;
						min-height: 84px;
						margin-bottom: 8px;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						border: 1px solid rgba(225,225,225,.6);
						&:hover {
							box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
						}
						.path-name {
							width: 110px;
							padding: 0 20px;
							font-size: 14px;
							line-height: 20px;
						}
						.path-box {
							flex: 1;
							width: 0;
							display: flex;
							flex-flow: wrap;
							padding-left: 25px;
							border-left: 1px solid hsla(0,0%,88.2%,.6);
							li {
								flex-shrink: 0;
								width: 33.333%;
								padding: 0 20px;
								margin: 6px 0;
								font-size: 14px;
								line-height: 20px;
								box-sizing: border-box;
								a {
									color: #666;
									&:hover {
										color: $--seller-primary;
									}
								}
							}
						}
					}
				}
				&:before {
					content: "";
					width: 1px;
					height: 100%;
					background-color: #e3e8ed;
					position: absolute;
					left: 130px;
					top: 40px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
</style>